import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { NS_HOME } from 'constants/i18n';
import { UserReviewsSection } from 'modules/shared';
import { FeatureCheckList } from 'components/common/FeatureCheckList';
import { SupportSection } from 'modules/support/components';
import { Hero, type HeroProps } from '../common/Hero';
import { HorizontalTrustBanner } from '../common/HorizontalTrustBanner';
import { CallToActionSection } from '../common/CallToActionSection';
import { ReasonsSection } from '../common/ReasonsSection';
import { StatsSection } from '../common/StatsSection';
import { FeaturesSection } from '../common/FeaturesSection';
import { CtaIllustration } from './CtaIllustration';
import { Illustration } from './Illustration';
import styles from './V3Page.module.scss';

const Page = dynamic(() => import('components/common/Page'), { ssr: false });

const V3Page = () => {
  const { t } = useTranslation(NS_HOME);

  const heroLeftContent: HeroProps['leftContent'] = {
    title: t('heroSection.buyingUsedCarTitle'),
    subtitle: t('landing.subtitle'),
    additionalContent: <FeatureCheckList className={styles.checklist} />,
  };

  const heroRightContent: HeroProps['rightContent'] = { illustration: <Illustration /> };

  return (
    <Page>
      <Hero
        className="!pb-0"
        color="colorBlue50"
        leftContent={heroLeftContent}
        rightContent={heroRightContent}
      />
      <HorizontalTrustBanner />

      <ReasonsSection reverse />

      <UserReviewsSection variant="blue" />

      <StatsSection color="colorBlue100" />

      <FeaturesSection />

      <CallToActionSection layout="right" illustration={<CtaIllustration />} />
      <SupportSection />
    </Page>
  );
};

export { V3Page };
